import PlanDetails from './PlanDetails.vue'
import axios from 'axios'
export default {
    components: {
        PlanDetails
    },
    data() {
        return {
            detailMsg: {
                port: { name: '' },
                country: { name: '' },
                newPriceArr: [[
                    {
                        area: {
                        name:''
                    }}
                ]]
            },
            tabShow: 1,
        }
    },
    created() {
        console.log(this.$route.query.id);
        this.getDetail(this.$route.query.id)
    },
    methods: {
        getDetail(id) { 
            axios.get(this.GLOBAL.BASE_URL4 + '/client/fba/fbaprice', {
                params: {
                    id
                }
            })
            .then(res => {
                this.detailMsg = res.data.result.rows[0]
                if (this.detailMsg.freight) {
                    const sortClass = (sortData) => {
                        const groupBy = (array, f) => {
                          let groups = {};
                          array.forEach((o) => {
                            let group = JSON.stringify(f(o));
                            groups[group] = groups[group] || [];
                            groups[group].push(o);
                          });
                          return Object.keys(groups).map((group) => {
                            return groups[group].sort((a, b) => { return a.startNumber - b.startNumber});
                          });
                        };
                        const sorted = groupBy(sortData, (item) => {
                          return item.area.name; // 返回需要分组的对象
                        });
                        console.log(sorted);
                        return sorted;
                    };
                    this.detailMsg.newPriceArr = sortClass(this.detailMsg.freight)
                    
                }
            })
            .catch(error => {
                console.log(error);
            })
        },
        placeOrder() {
            this.$router.push('/FBAOrder')
        }
    }
}